#page-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#title-404 {
  color: #444;
  font-size: 5rem;
  text-align: left;
  width: 100%;
}

#sub-title-404 {
  color: #444;
  padding: 20px;
  padding-top: 0px;
  text-align: center;
  font-size: 3rem;
  text-align: left;
  width: 100%;
}

#between-the-lines {
  color: #444;
  padding: 20px;
  text-align: center;
  font-size: 1.2rem;
  text-align: right;
  width: 100%;
  line-height: 3rem;
}

#button {
  cursor: pointer;
  width: 30rem;
  height: 10.4rem;
  border-radius: 0.8rem;
  font: 700 2.0rem Archivo;

  background: var(--color-secundary);
  color: var(--color-button-text);
  transition: background 0.2s;
  border: 0;
}

#button:hover {
  background: var(--color-secundary-dark);
}