ody {
  background-color: #fff; }

.showbox {
  position: relative;
  min-height: 100px;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column; }
  .showbox small {
    margin-top: 15px;
    font-size: 15px;
    letter-spacing: 0.4px; }

.loader {
  position: relative;
  margin: 0 auto;
  width: 45px;
  display: block; }
  .loader:before {
    content: '';
    display: block;
    padding-top: 100%; }

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.path {
  stroke-dasharray: 2, 200;
  stroke-dashoffset: 0;
  animation: dash 1s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes color {
  100%,
  0% {
    stroke: #dc3f53; }
  40% {
    stroke: #2d86ca; }
  66% {
    stroke: #009358; }
  80%,
  90% {
    stroke: #fabb0a; } }